import React from 'react';
import { createRoot } from 'react-dom/client';
import './style/main.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { KeycloakInstance } from 'keycloak-js';
import gtm from './analytics/gtm';
import * as Sentry from '@sentry/react';
import environment from './environments/environment';
// Declare global namespace for approval tool
declare global {
  interface Window {
    ApprovalTool: {
      keycloak: KeycloakInstance | null;
    };
  }
}
// Initalise the approval tool namespace if doesent exist
window.ApprovalTool = window.ApprovalTool || { keycloak: null };
if (environment.enableSentry) {
  Sentry.init({
    dsn:
      'https://8a430bfc99a44c1eb34f029dc029249f@o351552.ingest.sentry.io/5390062',
    environment: environment.name
  });
}
// Initialise Google Tag Manager
gtm.init();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
