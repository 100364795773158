import { EnvironmentConfig } from './environment';
import defaultEnv from './environment.default';
const env: EnvironmentConfig = {
  ...defaultEnv,
  name: 'greenlight-pl',
  enableSentry: true,
  authConfig: {
    clientId: 'approval-tool-ui',
    url: 'https://gb5.sandbox.keycloak.io.thehut.local/auth',
    realm: 'ingenuity-tools'
  },
  gtmEnv: 'env-5',
  gtmAuth: '6DNG4pdhhpNDALDk6gIJcg'
};
export default env;
