import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faComment,
  faBars,
  faWindowClose,
  faUser,
  faExpandArrowsAlt,
  faFileImage,
  faArrowCircleLeft,
  faCodeBranch,
  faWindowMinimize,
  faWindowMaximize,
  faMapSigns,
  faHome,
  faCheck,
  faTimes,
  faHighlighter,
  faSearch,
  faUndo,
  faPalette,
  faInfoCircle,
  faPlus,
  faFile,
  faTrash,
  faToggleOn,
  faToggleOff,
  faFighterJet,
  faExclamationTriangle,
  faClock,
  faThLarge,
  faStopwatch,
  faSignOutAlt,
  faSpinner,
  faChevronLeft,
  faChevronRight,
  faUpload,
  faArrowCircleUp,
  faImages,
  faThumbsUp,
  faThumbsDown,
  faCloudUploadAlt,
  faDownload,
  faChevronUp,
  faChevronDown,
  faReply,
  faPaperPlane,
  faEnvelope,
  faCircle,
  faSquare,
  faArrowUp,
  faPenNib,
  faDotCircle,
  faSlash,
  faSearchPlus,
  faSearchMinus,
  faUsers,
  faSun,
  faMoon,
  faCalendar,
  faPlay,
  faCheckCircle,
  faLock,
  faLockOpen,
  faList,
  faMinus,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

export const buildIconLibrary = () =>
  library.add(
    faExternalLinkAlt,
    faComment,
    faBars,
    faWindowClose,
    faUser,
    faExpandArrowsAlt,
    faFileImage,
    faArrowCircleLeft,
    faCodeBranch,
    faWindowMinimize,
    faWindowMaximize,
    faMapSigns,
    faHome,
    faCheck,
    faTimes,
    faHighlighter,
    faUndo,
    faSearch,
    faPalette,
    faInfoCircle,
    faPlus,
    faFile,
    faTrash,
    faUndo,
    faToggleOn,
    faToggleOff,
    faFighterJet,
    faExclamationTriangle,
    faClock,
    faThLarge,
    faStopwatch,
    faSignOutAlt,
    faSpinner,
    faChevronLeft,
    faChevronRight,
    faUpload,
    faArrowCircleUp,
    faImages,
    faThumbsUp,
    faThumbsDown,
    faCloudUploadAlt,
    faDownload,
    faChevronUp,
    faChevronDown,
    faReply,
    faPaperPlane,
    faEnvelope,
    faCircle,
    faSquare,
    faArrowUp,
    faPenNib,
    faDotCircle,
    faSlash,
    faSearchPlus,
    faSearchMinus,
    faUsers,
    faSun,
    faMoon,
    faCalendar,
    faPlay,
    faCheckCircle,
    faLock,
    faLockOpen,
    faList,
    faMinus
  );
