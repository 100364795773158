import { PALETTES, Theme } from '@thg-unify/lowry';
import { GreenlightTheme } from './theme.model';

export const lightTheme: GreenlightTheme = {
  ...Theme,

  COLOUR_MAIN: '#f3f3f3',
  COLOUR_GRAY: '#ccc',
  COLOUR_GRAY_ALT: '#b7b7b7',
  COLOUR_DARK_MODE: '#323639',
  COLOUR_MAIN_DARK: '#24252D',
  COLOUR_MAIN_DARKEST: '#55565D',
  COLOUR_WHITE: '#FFFFFF',
  COLOUR_BLACK: '#000000',
  COLOUR_FONT: '#FFFFFF',
  COLOUR_HIGHLIGHT: Theme.COLOURS.HIGHLIGHT,
  COLOUR_HIGHLIGHT_ALPHA: 'rgba(248, 53, 99, 0.6)',
  COLOUR_HIGHLIGHT_ALT: '#ce3156',
  COLOUR_ARTIFACT_BACKDROP: PALETTES.GREYS.DEFAULT,

  BORDER_RADIUS: '5px',

  TRANSITION_IN: 'all 0.3s ease',
  TRANSITION_OUT: 'all 0.1s ease',
  TRANSITION_SLOWER_IN: 'all 0.5s ease',

  BOX_SHADOW: 'shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1)',
  INSET_BOX_SHADOW: 'shadow: inset 0 - 35px 55px 0 rgba(0, 0, 0, 0.75)',

  STD_BLOCK_PADDING: '1rem',
  STD_ELEMENT_PADDING: '1rem',

  SLIDE_FROM_LEFT: ' 0.3s ease 0s 1 slideInFromLeft',

  TOGGLE_BTN_TOP: '100px',
};
