import envLive from './environment.live';
import envDefault from './environment.default';
import envPl from './environment.pl';
import { KeycloakConfig } from '../types';

/**
 * Look at the "REACT_APP_ENVIRONMENT" emvironment variable from build command,
 * use this to look up in the configs map in order to produce the correct
 * configuration for this application in it's given environment
 */
const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;

export interface EnvironmentConfig {
  name: string;
  enableSentry: boolean;
  authConfig: KeycloakConfig | null; // set to null to disable KC config
  s3Url: string;
  apiUrl: string;
  storageUrl: string;
  artifactThumbSize: {
    maxWidth: number;
    maxHeight: number;
  };
  jetEnabled: boolean; // Show artifact Jet integration?
  thumbnailCacheLengthSeconds: number; // The length of time to cache images in storage for
  backendStartDate: number; // The date when the backend was first deployed, ensures no wasted searches
  maxVersionNumber: number; // THe maximum number of versions that an artifact can have
  gtmEnv: string;
  gtmId: string;
  gtmAuth: string;
  uploadLimit: number;
}

interface ConfigLookup {
  [key: string]: EnvironmentConfig;
}

const configs: ConfigLookup = {
  development: envDefault,
  live: envLive,
  pl: envPl
};

export default currentEnvironment ? configs[currentEnvironment!] : envDefault;
