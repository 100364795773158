import { EnvironmentConfig } from './environment';
import defaultEnv from './environment.default';

const env: EnvironmentConfig = {
  ...defaultEnv,
  name: 'greenlight-prod',
  enableSentry: true,
  authConfig: {
    clientId: 'approval-tool-ui',
    url: 'https://keycloak3.thehut.net/auth/',
    realm: 'ingenuity-tools'
  },
  gtmEnv: 'env-6',
  gtmAuth: 'ia9r6hE2y_HslDayxCReTQ',
  uploadLimit: 100
};
export default env;
