import { Button, Icon, LowryContext } from '@thg-unify/lowry';
import React from 'react';
import s from './DeprecationMessage.styles';
import { lightTheme } from '../../themes/theme.light';

document.title = 'THG Greenlight has moved';

export const DeprecationMessage = () => {
  return (
    <LowryContext theme={lightTheme}>
      <s.WrapperWrapper>
        <s.Wrapper>
          <h1>THG Greenlight has moved!</h1>
          <s.Row>
            <img src="/greenlight-in-flow.png" alt="" />
            <div>
              <p>
                THG Greenlight now lives in THG Flow
                <br />
                We have made this deicison to support our mission of
                streamlining workflows, helping us to deliver features more
                efficiently and reducing any friction felt by our users.
              </p>
              <p>
                For support, please reach out to us at{' '}
                <a href="mailto: DL-Unify@thehutgroup.com">
                  <Icon icon="envelope" /> DL-Unify@thehutgroup.com
                </a>
              </p>
            </div>
          </s.Row>

          <a href="https://thgflow.com/greenlight/artifacts">
            <Button>
              Go to Flow
              <Icon icon="external-link-alt" />
            </Button>
          </a>
        </s.Wrapper>
      </s.WrapperWrapper>
    </LowryContext>
  );
};
