import { EnvironmentConfig } from './environment';

const env: EnvironmentConfig = {
  name: 'greenlight-dev',
  enableSentry: false,
  authConfig: {
    clientId: 'approval-tool-ui',
    // url: 'http://localhost:9090/auth',
    url: 'https://gb5.sandbox.keycloak.io.thehut.local/auth',
    realm: 'ingenuity-tools',
  },
  s3Url: '/s3',
  apiUrl: '/api',
  storageUrl: '/storage',
  artifactThumbSize: {
    maxWidth: 400,
    maxHeight: 400,
  },
  jetEnabled: false,
  thumbnailCacheLengthSeconds: 3600,
  backendStartDate: 1559520000,
  maxVersionNumber: 20,
  gtmId: 'GTM-M63VHGF', // This is the same across all GTM environments
  gtmEnv: 'env-5', // This varies by environment
  gtmAuth: '6DNG4pdhhpNDALDk6gIJcg', // This varies by environment
  uploadLimit: 100,
};
export default env;
