import TagManager, { TagManagerArgs } from 'react-gtm-module';
import environment from '../environments/environment';

const defaultArgs: TagManagerArgs = {
  gtmId: environment.gtmId,
  preview: environment.gtmEnv,
  auth: environment.gtmAuth
};

const init = (customArgs?: TagManagerArgs) => {
  TagManager.initialize(customArgs || defaultArgs);
};

export default {
  init
};
