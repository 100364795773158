import React from 'react';
// import { NavigationMain, ErrorHandler } from './presentational';
// import { StoreProvider } from '../store/StoreProvider';
import { buildIconLibrary } from '../utils/icons.utils';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { ArtifactListMain } from './artifacts';
// import { PageNotFound } from './presentational';
// import { AuthInit } from '../auth/AuthInit';
// import { ArtifactLoader } from './artifacts/ArtifactLoader/ArtifactLoader';
// import s from './App.styles';
// import { Toaster } from '../hooks/Toaster/Toaster';
// import { StyleProvider } from './StyleProvider/StyleProvider';
// import { MyReviews } from './MyReviews/MyReviews';
// import { FileUploadObserver } from './FileUploadObserver/FileUploadObserver';
import { DeprecationMessage } from './DeprecationMessage/DeprecationMessage';

const App = () => {
  buildIconLibrary();

  return <DeprecationMessage />;

  // const [fileUploading, setFileUploading] = useState(false);

  // return (
  //   <Router>
  //     <s.Main>
  //       <StoreProvider>
  //         <AuthInit>
  //           <StyleProvider>
  //             <FileUploadObserver
  //               fileUploading={fileUploading}
  //               setFileUploading={setFileUploading}
  //             />
  //             <s.Container>
  //               <NavigationMain />
  //               <s.View>
  //                 <Toaster>
  //                   <Routes>
  //                     <Route
  //                       path="/"
  //                       element={
  //                         <ArtifactListMain fileUploading={fileUploading} />
  //                       }
  //                     />
  //                     <Route
  //                       path="/artifacts"
  //                       element={
  //                         <ArtifactListMain fileUploading={fileUploading} />
  //                       }
  //                     />
  //                     <Route
  //                       path="/artifacts/:artifactId"
  //                       element={
  //                         <ArtifactLoader fileUploading={fileUploading} />
  //                       }
  //                     />
  //                     <Route
  //                       path="/artifacts/:artifactId/versions/:versionId"
  //                       element={
  //                         <ArtifactLoader fileUploading={fileUploading} />
  //                       }
  //                     />
  //                     <Route
  //                       path="/my-reviews/:artifactId"
  //                       element={
  //                         <ArtifactLoader fileUploading={fileUploading} />
  //                       }
  //                     />
  //                     <Route
  //                       path="/my-reviews/:artifactId/versions/:versionId"
  //                       element={
  //                         <ArtifactLoader fileUploading={fileUploading} />
  //                       }
  //                     />
  //                     <Route path="my-reviews" element={<MyReviews />} />
  //                     <Route path="*" element={<PageNotFound />} />
  //                   </Routes>
  //                   <div id="modal" />
  //                 </Toaster>
  //               </s.View>
  //               <ErrorHandler />
  //             </s.Container>
  //           </StyleProvider>
  //         </AuthInit>
  //       </StoreProvider>
  //     </s.Main>
  //   </Router>
  // );
};

export default App;
