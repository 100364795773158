import styled from 'styled-components';

const Wrapper = styled.div`
  width: 800px;
  max-width: 100%;
  padding: 1rem;

  > a,
  > h1,
  > div > div > p,
  > p {
    display: block;
    padding: 1rem;
  }

  p > a {
    font-weight: 900;
    color: ${(props) => props.theme.COLOURS.HIGHLIGHT};
    cursor: pointer;
  }

  p {
    line-height: 1.4;
  }

  img {
    border-radius: 1rem;
    width: 150px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  margin: 1rem;
`;

const WrapperWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`;

export default {
  Wrapper,
  Row,
  WrapperWrapper,
};
